<script>
  import { form as Form } from "svelte-forms";
  import Button from "../common/control/Button.svelte";
  import Input from "../common/control/Input.svelte";
  import Logo from "../common/Logo.svelte";
  import { connection, loggedInUserId, store, user } from "../store";
  import Layout from "../common/layout/LayoutAuth.svelte";
  import Loading from "../common/Loading.svelte";

  let username = "";
  let password = "";
  export let errors = [];
  let isLoading;

  const formLogin = Form(
    () => ({
      username: { value: username, validators: ["required"] },
      password: { value: password, validators: ["required"] },
    }),
    {
      initCheck: false,
      validateOnChange: false,
      stopAtFirstError: false,
      stopAtFirstFieldError: false,
    }
  );

  const handleSubmit = () => {
    isLoading.toggle();
    errors = [];
    formLogin.validate();
    const valid = $formLogin.valid;
    if (valid) {
      let user;
      errors = [];
      if (validateEmail(username)) {
        user = { email: username };
      } else {
        user = { username };
      }

      setTimeout(() => {
        $connection
          .login({
            password,
            user,
          })
          .then((user) => {
            loggedInUserId.set(user.userId);
            clearInput();
          })
          .catch((err) => {
            isLoading.toggle();
            errors = [err.reason];
            console.log(errors);
          });
      }, 1000);
    } else {
      isLoading.toggle();
    }
  };

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const clearInput = () => {
    username = "";
    password = "";
  };
</script>

<Loading text="Loading..." bind:this={isLoading} />
<Layout>
  <div
    class="max-w-md w-full p-6 border border-gray-200 bg-white shadow-lg rounded-md font-sans"
  >
    <div class="relative flex flex-wrap">
      <div class="w-full relative">
        <div
          class="p-4 bg-white shadow-lg rounded-full flex items-center justify-center w-40 h-40 absolute -top-28"
          style="left: 50%; margin-left: -75px;"
        >
          <Logo />
        </div>
        <h4 class="text-center text-3xl font-semibold text-dark-second pt-20">
          Bakery App
        </h4>
        <hr class="my-7 mx-5" />
        <form on:submit|preventDefault={() => handleSubmit()}>
          {#if errors.length > 0}
            <div class="bg-red-500 text-white text-center mb-3 mx-4">
              {errors[0]}
            </div>
          {/if}
          <div class="flex flex-col mb-5 space-y-2 px-5">
            <div>
              <Input
                label="Email"
                name="username"
                bind:value={username}
                form={formLogin}
              />
            </div>
            <div>
              <Input
                label="Password"
                name="password"
                bind:value={password}
                type="password"
                form={formLogin}
              />
            </div>
          </div>
          <div class="px-5">
            <Button
              status="primary"
              extClass="w-full"
              size="medium"
              rounded
              type="submit"
              on:click={handleSubmit}
            >
              Login
            </Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</Layout>
